function supportsDragnDrop() {

  var div = document.createElement('div');
  return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}

var droppedFiles = false;

var prevZoom = 0;
var pinchZoom = 0;

var frameWidth = 0;
var frameHeight = 0;

var image_sensitivity = 0;
var size = 0;
var result = 0;

var uploadedWidth = 0;
var uploadedHeight = 0;

var width = 0;
var height = 0;

var imageWidth = 0;
var imageHeight = 0;

var imageX = 0;
var imageY = 0;

var dragX = 0;
var dragY = 0;
var isDragging = false;
var prevX = 0;
var prevY = 0;

var prevDragX = 0;
var prevDragY = 0;

var stoppedX = 0;
var stoppedY = 0;

var oldX = 0;
var oldY = 0;
var xDirection = '';
var yDirection = '';

var canvas = 0;

jQuery( document ).ready(function($) {


  PinchZoom(PinchZoom);

  var editImage = jQuery('.edit-image');
  var uploadBox = jQuery('.uploader-box');
  var uploadInfo = jQuery('.uploader-info');
  var uploadPrice = jQuery('.pricerow');
  var uploadPresentation = jQuery('.uploader-presentation');

  image_sensitivity = jQuery('.uploader-file').data('image-sensitivity');
  frameWidth = jQuery('#select-size').find('option:selected').data('frame-width');
  frameHeight = jQuery('#select-size').find('option:selected').data('frame-height');

  canvas = jQuery('#select-size').find('option:selected').data('canvas');

  jQuery('input[name=frame-width]').val(frameWidth);
  jQuery('input[name=frame-height]').val(frameHeight);

  append_aspectRatio(frameWidth, frameHeight);
  price_logic();

  var $box = $('.uploader-box');

  jQuery('#select-size').change(function(e){

    canvas = jQuery('#select-size').find('option:selected').data('canvas');
    var clickFrom = e.target.id;
    var selected_size = jQuery('#select-size').find('option:selected');

    if (clickFrom == 'select-size') {
      frameWidth = selected_size.data('frame-width');
      frameHeight = selected_size.data('frame-height');

      jQuery('input[name=frame-width]').val(frameWidth);
      jQuery('input[name=frame-height]').val(frameHeight);
    }

    jQuery('#zoom').val('100');
    dragX = 0;
    dragY = 0;

    checkImageSize(frameWidth,frameHeight,image_sensitivity);
    append_aspectRatio(frameWidth, frameHeight);
    price_logic();
    moveImage();
  });

  jQuery('#select-type').change(function(e){
    price_logic();
  });

  jQuery('.quantity').change(function(){

    if(!jQuery(this).val() || jQuery('#file').val() == '' || jQuery(this).val() <= 0) {
      jQuery('#submit-image').prop('disabled', true);
    } else {
      jQuery('#submit-image').prop('disabled', false);
    }

  });

  jQuery('#imagetool-uploader').submit(function(e){
    e.preventDefault();
    jQuery('#submit-image').prop('disabled', true);
    this.submit();
  })

  if(supportsDragnDrop) {
    $box.addClass('has-support');

    $box.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.stopPropagation();
    })

    .on('dragover dragenter', function() {
      $box.addClass('is-dragover');
    })

    .on('dragleave dragend drop', function() {
      $box.removeClass('is-dragover');
    })

    .on('drop', function(e) {
    });
  }

  jQuery('.uploader-box').on('change','#file' , function(e){
    editImage.show();
    uploadBox.hide();
    uploadInfo.css("display","flex");
    uploadPrice.show();
    uploadPresentation.hide();

    var file = e.originalEvent.target.files[0];
    jQuery('#submit-image').prop('disabled', false);

    if (file.type != 'image/jpeg') {
      alert('Filformatet måste vara jpeg');
      jQuery('.uploader-file').val('');
      jQuery('#submit-image').prop('disabled', true);

      editImage.hide();
      uploadBox.show();
      uploadInfo.hide();
      uploadPrice.hide();
      uploadPresentation.show();

      return;
    }

    var img = document.createElement('img');
    img.src = window.URL.createObjectURL(file);

    img.onload = function() {
      uploadedWidth = this.width;
      uploadedHeight = this.height;

      var selected_size = jQuery('#select-size').find('option:selected');

      checkImageSize(frameWidth,frameHeight,image_sensitivity);

      onDrop(e);
      moveImage();

      var offsetEditImage = jQuery(".edit-image").offset().top;
      jQuery("html, body").stop().animate({
        scrollTop: offsetEditImage+"px",
      }, 500);

      if (canvas != 0) {
        jQuery('#helperCanvas').fadeIn(250);
      }


    };

  });

  jQuery('.change-image').click(function(){

    editImage.hide();
    uploadBox.show();
    uploadInfo.hide();
    uploadPrice.hide();
    uploadPresentation.show();

    jQuery('#submit-image').prop('disabled', true);
    jQuery('#zoom').val('100');
    dragX = 0;
    dragY = 0;

    var preview_img = jQuery('.uploader-box');
    var uploaded_file = jQuery('.uploader-file');

    jQuery('.init').show();
    jQuery('.uploaded').hide();
    uploaded_file.show();

    uploaded_file.css('visibility', 'visible');
    uploaded_file.val('');
    jQuery('.uploader-buttons').hide();

    jQuery(".show-edit-image").removeClass("grayscale");

  });


  jQuery('.uploader-edit-button').click(function(){
    moveImage();
  });

  jQuery('#submit-image').click(function(){

    var resizeDiff = editImageWidth / frameWidth;

    jQuery('input[name=zoom]').val(jQuery('#zoom').val());
    jQuery('input[name=x]').val(imageX / resizeDiff);
    jQuery('input[name=y]').val(imageY / resizeDiff);

    jQuery('input[name=width]').val(imageWidth / resizeDiff);
    jQuery('input[name=height]').val(imageHeight / resizeDiff);

  });

  jQuery('#black-white').click(function() {

    if(jQuery(this).is(':checked')) {

      jQuery(".show-edit-image").addClass("grayscale");

      jQuery('input[name=black-white]').val('yes');
    }

    if(!jQuery(this).is(':checked')) {

      jQuery(".show-edit-image").removeClass("grayscale");

      jQuery('input[name=black-white]').val('no');
    }

  });

  jQuery(document).on("click",".imagetool-helper-close", function (event) {
    jQuery(this).parent().fadeOut(250);
  });


  /*------------------------------------------------------------------------------------------------------------------*/
  var BrowserDetect = {
    init: function () {
      this.browser = this.searchString(this.dataBrowser) || "Other";
      this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
      for (var i = 0; i < data.length; i++) {
          var dataString = data[i].string;
          this.versionSearchString = data[i].subString;

          if (dataString.indexOf(data[i].subString) !== -1) {
              return data[i].identity;
          }
      }
    },
    searchVersion: function (dataString) {
      var index = dataString.indexOf(this.versionSearchString);
      if (index === -1) {
          return;
      }

      var rv = dataString.indexOf("rv:");
      if (this.versionSearchString === "Trident" && rv !== -1) {
          return parseFloat(dataString.substring(rv + 3));
      } else {
          return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
      }
    },

    dataBrowser: [
      {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
      {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
      {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
      {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
      {string: navigator.userAgent, subString: "Opera", identity: "Opera"},
      {string: navigator.userAgent, subString: "OPR", identity: "Opera"},

      {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
      {string: navigator.userAgent, subString: "Safari", identity: "Safari"}
    ]
};

BrowserDetect.init();

  window.addEventListener("resize", function(){
    moveImage();
  });


  if ( BrowserDetect.browser == 'Explorer') {
    jQuery('#zoom').on('change', function() {
      moveImage();
      checkInsideFrame();
    });
  } else {
    jQuery('#zoom').on('input', function() {
      moveImage();
      checkInsideFrame();
    });
  }

  jQuery(".show-edit-image").on('mousedown touchstart',function(e){
    stopBodyScrolling(true);

    isDragging = true;
    prevX = 0;
    prevY = 0;
  });

  jQuery(window).on('mouseup touchend' ,function(){
    stopBodyScrolling(false);

    isDragging = false;
    prevX = 0;
    prevY = 0;
  });

  jQuery(window).on('mousemove touchmove',function(event) {
    var move = '';

    if (event.type == 'touchmove') {
      move = event.originalEvent.targetTouches[0];
    } else  {
      move = event;
    }

    var mouseDirection = getMouseDirection(event);

    if(isDragging == true)
    {
      var resizeDiff = editImageWidth / frameWidth;
      unFocus();

      if( prevX>0 || prevY>0) {

        dragX += (move.pageX - prevX) / resizeDiff;
        dragY += (move.pageY - prevY) / resizeDiff;

        if (stoppedX === mouseDirection || stoppedY === mouseDirection) {

          if (jQuery('#zoom').val() != '100'){

            if (stoppedX === 'left' || stoppedX === 'right') {
              dragX = prevDragX;
            }

            if (stoppedY === 'top' || stoppedY === 'bottom') {
              dragY = prevDragY;
            }

          }

        }
        moveImage();
      }

    }

    prevX = move.pageX;
    prevY = move.pageY;

    prevDragX = dragX;
    prevDragY = dragY;

  });

  function PinchZoom() {
    hammertime = new Hammer(document.querySelector('.show-edit-image'), {});
    hammertime.get('pinch').set({
        enable: true
    });
    var scale = 1,last_scale = 1;

    hammertime.on('doubletap pan pinch panend pinchend', function(ev) {
        //pinch
        if (ev.type == "pinch") {
          scale = Math.max(100, Math.min(last_scale * (0+ev.scale), 200));
          jQuery('#zoom').val(scale);
          moveImage();
        }

        if (ev.type == "pinchend"){
          last_scale = scale;
        }
    });
  }

  function stopBodyScrolling (bool) {
    if (bool === true) {
        document.body.addEventListener("touchmove", freezeVp, false);
    } else {
        document.body.removeEventListener("touchmove", freezeVp, false);
    }
  }

  var freezeVp = function(e) {
    if (BrowserDetect.browser == 'Safari') {
      e.preventDefault();
    }

    if (BrowserDetect.browser == 'Chrome') {
      jQuery('body').addClass('chromeScroll');
    }

  };

  var unFocus = function () {
    if (document.selection) {
      document.selection.empty()
    } else {
      window.getSelection().removeAllRanges()
    }
  }

});

var editImageWidth = 0;
var editImageHeight = 0;

function setEditImageFrameSize() {

  var availibleWidth = jQuery(".responsive-frame").width();
  var availibleHeight = jQuery(".responsive-frame").height();

   // If landscape
  if (frameHeight <= frameWidth) {
    editImageWidth = availibleWidth;
    editImageHeight = (frameHeight / frameWidth) * availibleWidth;
  }

  // If potrait
  if (frameHeight >= frameWidth) {
    editImageWidth = (frameWidth / frameHeight) * availibleHeight;
    editImageHeight = availibleHeight;
  }

  if (editImageWidth > availibleWidth) {
    editImageWidth = availibleWidth;
    editImageHeight = (frameHeight / frameWidth) * availibleWidth;
  }

  if (editImageHeight > availibleHeight) {
    editImageWidth = (frameWidth / frameHeight) * availibleHeight;
    editImageHeight = availibleHeight;
  }


  // console.log('frameWidth: '+frameWidth+' frameHeight: '+frameHeight+' aspect ratio: '+(frameWidth/frameHeight));
  // console.log('availibleWidth: '+availibleWidth+' availibleHeight: '+availibleHeight);
  // console.log('editImageWidth: '+editImageWidth+' editImageHeight: '+editImageHeight+' aspect ratio: '+(editImageWidth/editImageHeight));

  jQuery(".show-edit-image").css({
    'width': editImageWidth+'px',
    'height': editImageHeight+'px'
  });
}

function moveImage() {

  setEditImageFrameSize();

  var zoom = jQuery('#zoom').val();
  var resizeDiff = editImageWidth / frameWidth;

  imageWidth = (uploadedWidth * zoom / 100) * resizeDiff;
  imageHeight = (uploadedHeight * zoom / 100) * resizeDiff;

   // If landscape

  if (uploadedWidth <= frameWidth) {

    frameImgWidth = editImageWidth;
    frameImgHeight = (uploadedHeight / uploadedWidth) * editImageWidth;

    if (frameImgHeight <= editImageHeight) {

      frameImgWidth = (uploadedWidth / uploadedHeight) * editImageHeight;
      frameImgHeight = editImageHeight;
    }
  }

  if (uploadedWidth >= frameWidth) {

      frameImgWidth = editImageWidth;
      frameImgHeight = (uploadedHeight / uploadedWidth) * editImageWidth;

    if (frameImgHeight <= editImageHeight) {
      frameImgWidth = (uploadedWidth / uploadedHeight) * editImageHeight;
      frameImgHeight = editImageHeight;
    }

  }


  // If potrait
  // (original height / original width) x new width = new height
  // (original width / original height) x new height = new width

/*
  if (frameHeight >= frameWidth) {
    console.log('portrait');
    if (uploadedWidth <= frameWidth) {
      console.log('portrait1');
      frameImgWidth = editImageWidth
      frameImgHeight = (uploadedHeight / uploadedWidth) * editImageWidth;
    }

    if (uploadedWidth >= frameWidth) {
      console.log('portrait2');
      frameImgWidth = editImageWidth
      frameImgHeight = (uploadedHeight / uploadedWidth) * editImageWidth;

      if (frameImgHeight <= editImageHeight) {
        frameImgWidth = (uploadedWidth / uploadedHeight) * editImageHeight;
        frameImgHeight = editImageHeight
      }
    }

  }
*/

  imageWidth = (frameImgWidth * zoom / 100);
  imageHeight = (frameImgHeight * zoom / 100);

  imageX = ((editImageWidth - imageWidth) / 2) + (dragX * resizeDiff);
  imageY = ((editImageHeight - imageHeight) / 2) + (dragY * resizeDiff);

  checkInsideFrame();

  jQuery(".show-edit-image").css({
    'background-position': imageX+'px '+imageY+'px',
    'background-size':  imageWidth+'px '+imageHeight+'px',
  });

  var newCanvas = (canvas * resizeDiff);

  if (canvas != '') {
    jQuery(".image-canvas").css({
      'border': newCanvas+'px solid #ccc',
    });
  }else {
    jQuery(".image-canvas").css({
      'border': 'none',
    });
  }

}


/*------------------------------------------------------------------------------------------------------------------*/

function checkInsideFrame() {

  if (imageX >= 0 || imageX < (editImageWidth - imageWidth) || imageY >= 0 || imageY < (editImageHeight - imageHeight)) {

    if (imageX >= 0) {
      imageX = 0;
      stoppedX = 'left';
    }

    if (imageX < (editImageWidth - imageWidth)) {
      imageX = editImageWidth - imageWidth;
      stoppedX = 'right';
    }

    if (imageY >= 0) {
      imageY = 0;
      stoppedY = 'top';
    }

    if (imageY < (editImageHeight - imageHeight)) {
      imageY = editImageHeight - imageHeight;
      stoppedY = 'bottom';
    }

  } else {

    stoppedX = 0;
    stoppedY = 0;
  }
}

function getMouseDirection(e) {

    var Direction = '';
    var deltaX = oldX - e.pageX;
    var deltaY = oldY - e.pageY;

    if (deltaX < deltaY && deltaX < 0) {
        Direction = "left";
    } else if (deltaX > deltaY && deltaX > 0) {
        Direction = "right";
    } else if (deltaY < deltaX && deltaY < 0) {
        Direction = "top";
    } else if (deltaY > deltaX && deltaY > 0) {
        Direction = "bottom";
    }

    oldX = e.pageX;
    oldY = e.pageY;

    return Direction;
}

function checkImageSize(fwidth,fheight,sensitivity) {

  if (fwidth > fheight) {
    size = fwidth - (fwidth * ((sensitivity < 10) ? ('0.'+convert_to_percent(sensitivity)) : '0.'+sensitivity));
    if (uploadedWidth < size) {
      jQuery('.error').show();
    } else {
      jQuery('.error').hide();
    }

  } else if (fheight > fwidth) {
    size = fheight - (fheight * ((sensitivity < 10) ? ('0.'+convert_to_percent(sensitivity)) : '0.'+sensitivity));
    if (uploadedHeight < size) {
      jQuery('.error').show();
    } else {
      jQuery('.error').hide();
    }
  }

}

function append_aspectRatio(width, height) {
 if (width >= height || width == height) { //landscape
    jQuery('.responsive-frame').removeClass('portrait');
    jQuery('.responsive-frame').addClass('landscape');
  }else { //portrait
    jQuery('.responsive-frame').removeClass('landscape');
    jQuery('.responsive-frame').addClass('portrait');
  }
}

function onDrop(e) {

 var buttons = jQuery('.uploader-buttons');
 var image_edit_box = jQuery('.show-edit-image');

 buttons.css("display","flex");
  jQuery('.init').hide();
  jQuery('.uploaded').show();
  jQuery('.uploader-file').hide();

 jQuery.each(e.originalEvent.target.files, function (index, file) {
  var img = document.createElement('img');

  img.src = window.URL.createObjectURL(file);

  image_edit_box.css({
    'background-image' : 'url('+img.src+')',
    'background-repeat' : 'no-repeat',
  });
});

}

function price_logic() {

  var selected_price = jQuery('#select-size').find('option:selected');
  var selected_extra_price = jQuery('#select-type').find('option:selected');
  var price = selected_price.data('price');

  var int = selected_extra_price.data('int');
  var percent = selected_extra_price.data('percent');

  var display_price = jQuery('.display-price');
  var set_ground_price = jQuery('input[name=price_ground]');
  var set_price = jQuery('input[name=price]');
  var set_price_extra = jQuery('input[name=price_extra]');
  var set_extra_type = jQuery('input[name=extra_type]');
  var extra;
  var extra_type;

  set_ground_price.empty();
  set_ground_price.val(price);

  if (int) {
    price += int;
    extra = int;
    extra_type = 'int';
  }
  if (percent) {
    price *= Number('1.'+convert_to_percent(percent));
    extra = Number(convert_to_percent(percent));
    extra_type = 'percent';
  }

  display_price.empty();
  set_extra_type.empty();
  set_price_extra.empty();
  set_price.empty();


  // display_price.append(Math.round(price / 10) * 10 +' kr');
  display_price.append(Math.round(price) +' kr');
  set_extra_type.val(extra_type);
  set_price_extra.val(extra);
  set_price.val(Math.round(price));
  // set_price.val(Math.round(price / 10) * 10);
}

function convert_to_percent(percent) {
  return (percent < 10) ? ('0' + percent) : percent;
}
