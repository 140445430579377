/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).load(function(){
          $( ".site-loader" ).fadeOut();
        });
        window.cookieconsent_options = {"message":"Vi använder cookies för att förbättra din upplevelse på vår webbplats!","dismiss":"Jag förstår","learnMore":"Information om cookies","link":"http://ericsonsfoto.se/information-om-cookies/","theme":"dark-bottom"};
        $('.hero-slider').flickity({
          cellAlign: 'left',
          contain: true,
          autoPlay: 6200
        });
        $('.flexible-content__slider__gallery').flickity({
          cellAlign: 'left',
          contain: true,
          imagesLoaded: true,
          pageDots: false
        });
        $('.product-slider').flickity({
          cellSelector: '.product',
          cellAlign: 'left',
          imagesLoaded: true,
          contain: true
        });
        $('.entry-content__slider').flickity({
          cellAlign: 'left',
          imagesLoaded: true,
          wrapAround: true,
          pageDots: false,
          autoPlay: 5200
        });
          // Shop mobile menu
          var button = $('.sidebar-menu-button');
          var closeButton = $('.close-nav');
          var nav = $('.sidebar');
          var overlay = $('.sidebar__overlay');

          button.click(function () {
            nav.toggleClass('sidebar--visible');
            overlay.toggleClass('sidebar__overlay--visible');
          });

          overlay.click(function () {
            nav.removeClass('sidebar--visible');
            overlay.removeClass('sidebar__overlay--visible');
          });

          // Site menu mobile
          var mobileButton = $('.mobile-menu');
          var mobileNavigation = $('.main-header__lower');
          mobileButton.click(function () {
            mobileNavigation.toggleClass('main-header__lower--visible');
          });
        },
        finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_kontakt': {
      init: function() {

        // Create a map object and specify the DOM element for display.

        var mapOptions = {
          center: new google.maps.LatLng(57.655571,12.013829),
          zoom: 17,
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.DEFAULT,
          },
          disableDoubleClickZoom: true,
          mapTypeControl: false,
          scaleControl: false,
          scrollwheel: false,
          panControl: false,
          streetViewControl: false,
          draggable : true,
          overviewMapControl: false,
          overviewMapControlOptions: {
            opened: false,
          },
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [
          {
            "featureType": "landscape",
            "stylers": [
            {
              "hue": "#F1FF00"
            },
            {
              "saturation": -27.4
            },
            {
              "lightness": 9.4
            },
            {
              "gamma": 1
            }
            ]
          },
          {
            "featureType": "road.highway",
            "stylers": [
            {
              "hue": "#0099FF"
            },
            {
              "saturation": -20
            },
            {
              "lightness": 36.4
            },
            {
              "gamma": 1
            }
            ]
          },
          {
            "featureType": "road.arterial",
            "stylers": [
            {
              "hue": "#00FF4F"
            },
            {
              "saturation": 0
            },
            {
              "lightness": 0
            },
            {
              "gamma": 1
            }
            ]
          },
          {
            "featureType": "road.local",
            "stylers": [
            {
              "hue": "#FFB300"
            },
            {
              "saturation": -38
            },
            {
              "lightness": 11.2
            },
            {
              "gamma": 1
            }
            ]
          },
          {
            "featureType": "water",
            "stylers": [
            {
              "hue": "#00B6FF"
            },
            {
              "saturation": 4.2
            },
            {
              "lightness": -63.4
            },
            {
              "gamma": 1
            }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
            {
              "hue": "#9FFF00"
            },
            {
              "saturation": 0
            },
            {
              "lightness": 0
            },
            {
              "gamma": 1
            }
            ]
          }
          ],
        };
        var mapElement = document.getElementById('karta');
        var map = new google.maps.Map(mapElement, mapOptions);
        var locations = [
        ['Ericsons Foto', 'undefined', 'undefined', 'undefined', 'undefined', 57.65540780000001, 12.013697500000035, 'https://mapbuildr.com/assets/img/markers/default.png']
        ];
        for (i = 0; i < locations.length; i++) {
          if (locations[i][1] ==='undefined'){ description ='';} else { description = locations[i][1];}
          if (locations[i][2] ==='undefined'){ telephone ='';} else { telephone = locations[i][2];}
          if (locations[i][3] ==='undefined'){ email ='';} else { email = locations[i][3];}
          if (locations[i][4] ==='undefined'){ web ='';} else { web = locations[i][4];}
          if (locations[i][7] ==='undefined'){ markericon ='';} else { markericon = locations[i][7];}
          marker = new google.maps.Marker({
            icon: markericon,
            position: new google.maps.LatLng(locations[i][5], locations[i][6]),
            map: map,
            title: locations[i][0],
            desc: description,
            tel: telephone,
            email: email,
            web: web
          });
          link = '';     }
        }
      },
      'page_template_template_faq': {
        init: function() {

          $('.js-accordion-trigger').bind('click', function(e){
          jQuery(this).parent().find('.submenu').slideToggle('fast');  // apply the toggle to the ul
          jQuery(this).parent().toggleClass('is-expanded');
          e.preventDefault();
        });
        }
      }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


















